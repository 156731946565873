import React from "react";
import { LayoutContentWrapperStyle } from "./layoutWrapper.style";

const LayoutContentWrapper= props => (
  <LayoutContentWrapperStyle
    className={
      props.className != null
        ? `${props.className} isoLayoutContentWrapper`
        : "isoLayoutContentWrapper"
    }
    {...props}
  >
    {props.children}
  </LayoutContentWrapperStyle>
);

export default LayoutContentWrapper;
