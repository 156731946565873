import React, { useEffect, useState } from "react";
import { Wrapper } from "./cards.style";
import * as ClipboardPolyfill from "clipboard-polyfill";

export default function Cards(props) {
  const { title, description, apiButtonText, samplereqText, responses, codes } =
    props;
  const [response, setResponse] = useState({
    // totalDistance: 471,
    // msg: "ok",
    // totalmeter: 471,
  });

  const [code, setCode] = useState({
    Test: "here",
    msg: "lets",
  });

  useEffect(() => {
    setResponse(responses[200]);
  }, []);
  const [copiedMessage, setCopiedMessage] = useState("");
  const [clickedButton, setClickedButton] = useState(null);
  const [clickedResponseButton, setClickedResponseButton] = useState(200);
  const [clickedCodeButton, setClickedCodeButton] = useState("html");

  const handleButtonClick = (statusCode, customResponse) => {
    setResponse(customResponse);
    setClickedResponseButton(statusCode);
  };

  const handleCodeButtonClick = (codeType, codeResponse) => {
    setCode(codeResponse);
    setClickedCodeButton(codeType);
  };

  const handleCopy = (text, buttonId) => {
    ClipboardPolyfill.writeText(text).then(
      () => {
        setCopiedMessage("Copied!");
        setTimeout(() => setCopiedMessage(""), 2000);
      },
      (err) => {
        console.error("Failed to copy text: ", err);
      }
    );

    setClickedButton(buttonId);
    setTimeout(() => setClickedButton(null), 2000);
  };

  return (
    <Wrapper>
      <div className="geocontainer ">
        <div className="geocard">
          <div>
            <div className="geotitle">{title}</div>
            <div className="geobgparagraph">{description}</div>

            <div className="geobgparagraph">
              Use the following URL and Sample Request to get started.
            </div>

            <div className="subtitle">Sample Request</div>
            <br />
            <div className="relative w-auto h-9 flex items-center justify-start bg-gray-100  rounded-md overflow-hidden">
              <div
                className="flex items-center pr-2 overflow-x-auto scrollbar-thin samplereq"
                style={{ height: "100%", width: "100%" }}
              >
                <span className="text-sm text-black whitespace-nowrap ml-2 max-w-xs ">
                  {apiButtonText}
                </span>
              </div>
              <button
                className={`w-16 h-9 bg-[#6777CC] text-white text-sm flex items-center justify-center rounded-md ${
                  clickedButton === "apiButton" ? "border border-green-500" : ""
                }`}
                onClick={() => handleCopy(apiButtonText, "apiButton")}
              >
                {clickedButton === "apiButton" ? "Copied!" : "Copy"}
              </button>
            </div>

            <div className="subtitle">Sample response</div>
            <div className="relative h-52 w-auto flex items-start justify-start bg-gray-100 rounded-md p-2 overflow-hidden mt-6">
              <button
                className={`h-7 w-14 bg-[#6777CC] text-white text-sm flex items-center justify-center rounded-md m-2 ${
                  clickedResponseButton === 200 ? "border bg-black" : ""
                }`}
                onClick={() => handleButtonClick(200, responses[200])}
              >
                200
              </button>
              <button
                className={`h-7 w-14 bg-[#6777CC] text-white text-sm flex items-center justify-center rounded-md m-2 ${
                  clickedResponseButton === 400 ? "border bg-black" : ""
                }`}
                onClick={() => handleButtonClick(400, responses[400])}
              >
                400
              </button>
              <button
                className={`h-7 w-14 bg-[#6777CC] text-white text-sm flex items-center justify-center rounded-md m-2 ${
                  clickedResponseButton === 401 ? "border bg-black" : ""
                }`}
                onClick={() => handleButtonClick(401, responses[401])}
              >
                401
              </button>
              <div
                className="absolute mt-10 px-2 py-2 pb-16 pl-8 overflow-y-auto max-h-full scrollbar-thin geoparag"
                style={{
                  whiteSpace: "pre-wrap",
                  width: "100%",
                  boxSizing: "border-box",
                }}
              >
                <pre>{JSON.stringify(response, null, 2)}</pre>
              </div>
            </div>
            {/* <div className="subtitle">Sample code</div>
            <div className="relative h-72 w-auto flex items-start justify-start bg-gray-100 rounded-md p-2 overflow-hidden mt-6 ">
              <button
                className={`h-7 w-20 bg-[#6777CC] text-white text-sm flex items-center justify-center rounded-md m-2 ${
                  clickedCodeButton === "html" ? "border bg-black" : ""
                }`}
                onClick={() => handleCodeButtonClick("html", codes["html"])}
              >
                Html
              </button>
              <button
                className={`h-7 w-20 bg-[#6777CC] text-white text-sm flex items-center justify-center rounded-md m-2 ${
                  clickedCodeButton === "javascript"
                    ? "border bg-black"
                    : ""
                }`}
                onClick={() =>
                  handleCodeButtonClick("javascript", codes["javascript"])
                }
              >
                Javascript
              </button>
              <button
                className="absolute inset-y-0 right-0 w-16 h-9 bg-[#6777CC] text-white text-sm flex items-center justify-center rounded-md"
                onClick={() =>
                  handleCopy(JSON.stringify(code, null, 2), "codeButton")
                }
              >
                {clickedButton === "codeButton" ? "Copied!" : "Copy"}
              </button>
              <div className="absolute mt-10 px-2 py-2 pb-16 pl-8 overflow-y-auto max-h-full scrollbar-thin geoparag">
                <pre>{JSON.stringify(code, null, 2)}</pre>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
