import React, { useState } from "react";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { Modal } from "antd";
import EditProfileForm from "../Page/editprofile";
import { connect } from "react-redux";
import { logout } from "../../redux/auth/authState";
import { useNavigate } from "react-router-dom";

const Topbar = ({
  toggleSidebar,
  isSidebarOpen,
  isLoggedIn,
  logout,
  loggedInUser,
}) => {
  const [isCardVisible, setCardVisible] = useState(false);
  const [isModalVisible, setModalVisible] = useState(false);

  const navigate = useNavigate();

  const toggleCardVisibility = () => {
    setCardVisible(!isCardVisible);
  };

  const showModal = () => {
    setModalVisible(true);
  };

  const handleModalCancel = () => {
    setModalVisible(false);
  };

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  return (
    <div
      className={`text-white h-16 flex  items-center justify-between p-4  top-0 z-10 w-full w-full`}
      style={{ background: "#DADAE6" }}
    >
      <div className="flex items-center">
        <button
          className="text-2xl h-full inline-flex items-center justify-center bg-transparent border-0 outline-0 relative cursor-pointer mr-14"
          onClick={toggleSidebar}
        >
          &#9776; {/* Burger menu icon */}
        </button>
      </div>
      <div
        className={`flex items-center ml-4 relative ${
          !isLoggedIn ? "hidden" : ""
        }`}
      >
        <div
          className="w-10 h-10 rounded-full flex items-center justify-center cursor-pointer"
          onClick={toggleCardVisibility}
          style={{ background: "#898989" }}
        >
          <span style={{ lineHeight: 1 }}>
            <PermIdentityIcon />
          </span>
        </div>
        {isCardVisible && (
          <div
            className="card"
            style={{
              padding: "10px 0px 15px 0px",
              flexDirection: "column",
              backgroundColor: "rgb(255, 255, 255)",
              width: "180px",
              minWidth: "160px",
              flexShrink: 0,
              borderRadius: "5px",
              boxShadow: "rgba(0, 0, 0, 0.2) 0px 2px 10px",
              transition: "all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s",
              position: "absolute",
              top: "100%",
              zIndex: 1,
              marginLeft: "-150px",
            }}
          >
            {/* Card content goes here */}
            <h1
              style={{
                fontSize: "16px",
                color: "rgb(89, 89, 89)",
                lineHeight: "1.1",
                padding: "7px 15px",
                backgroundColor: "transparent",
                display: "flex",
                justifyContent: "flex-start",
                fontWeight: "bold",
              }}
            >
              {loggedInUser.name}
            </h1>
            <a
              href="#"
              onClick={showModal}
              style={{
                fontSize: "13px",
                color: "rgb(89, 89, 89)",
                lineHeight: "1.1",
                padding: "7px 15px",
                backgroundColor: "transparent",
                textDecoration: "none",
                display: "flex",
                justifyContent: "flex-start",
                transition: "all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s",
              }}
            >
              Profile
            </a>
            {isModalVisible && (
              <Modal
                title="Edit Profile"
                open={isModalVisible}
                onCancel={handleModalCancel}
                footer={null}
              >
                <EditProfileForm loggedInUser={loggedInUser} /> {/* Pass loggedInUser prop */}
              </Modal>
            )}


            <a
              href="#"
              style={{
                fontSize: "13px",
                color: "rgb(89, 89, 89)",
                lineHeight: "1.1",
                padding: "7px 15px",
                backgroundColor: "transparent",
                textDecoration: "none",
                display: "flex",
                justifyContent: "flex-start",
                transition: "all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s",
              }}
              onClick={handleLogout}
            >
              Logout
            </a>
          </div>
        )}
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    isLoggedIn: state.auth.idToken !== null ? true : false,
    loggedInUser: state.auth.loggedInUser,
  };
}

const mapDispatchToProps = {
  logout: logout,
};
export default connect(mapStateToProps, mapDispatchToProps)(Topbar);
