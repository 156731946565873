import React from "react";
import styled from "styled-components";

const StyledLeftButton = styled.button`
  width: 155px;
  height: 40px;
  border-radius: 10px;
  border: ${(props) => props.buttonStyle.border };
  background: ${(props) => props.buttonStyle.background || "#FFFFFF"};
  margin-right: 5px;
  transition: background-color 0.3s;

  &:hover {
    background: #faab33;
    color: #ffffff; /* Change text color to white on hover */
  }
`;

const StyledRightButton = styled.button`
  width: 155px;
  height: 40px;
  border-radius: 10px;
  border: ${(props) => props.buttonStyle.border };
  background: ${(props) => props.buttonStyle.background || "#FF6A4C"};
  margin-left: 5px;
  transition: background-color 0.3s;

  &:hover {
    background: #faab33;
    color: #ffffff; /* Change text color to white on hover */
  }
`;

const ButtonGroup = ({
  leftButtonText,
  rightButtonText,
  onLeftButtonClick,
  onRightButtonClick,
  leftButtonStyle,
  rightButtonStyle,
}) => {
  return (
    <div style={{ display: "flex" }}>
      <StyledLeftButton
        buttonStyle={leftButtonStyle}
        onClick={onLeftButtonClick}
      >
        {leftButtonText}
      </StyledLeftButton>
      <StyledRightButton
        buttonStyle={rightButtonStyle}
        onClick={onRightButtonClick}
      >
        {rightButtonText}
      </StyledRightButton>
    </div>
  );
};

export { ButtonGroup };
