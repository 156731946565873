import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    idToken: localStorage.getItem("clientToken"),
    loggedInUser: {},
    updatedApiKey: null,
    services:[],
    error: null,
    signinSuccess: false,
  },
  reducers: {
    signup: (state, action) => {},
    updateApi: (state, action) => {
      console.log(action.payload);
      state.updatedApiKey = action.payload;

      console.log(state.updatedApiKey);
    },
    updateService: (state, action) => {
      console.log(action.payload);
      state.services = action.payload;
    },
    login: (state, action) => {},
    getLoggedInUser: (state, action) => {},
    getLoggedInUserSuccess: (state, action) => {
      state.loggedInUser = action.payload;
    },
    loginSuccess: (state, action) => {
      state.idToken = action.payload.token;
      state.error = null;
      state.signinSuccess = true;
    },
    loginFailure: (state, action) => {
      state.idToken = null;
      state.error = action.payload; // Set the error message in the state
    },
    logout: (state) => {},
    logoutSuccess: (state) => {
      state.idToken = null;
      state.signinSuccess = false;
      state.error = null;
    },
  },
});

export const {
  login,
  logout,
  signup,
  update,
  updateApi,
  updateService,
  loginSuccess,
  signupSuccess,
  signupSuccesslogout,
  loginFailure,
  logoutSuccess,
  getLoggedInUser,
  getLoggedInUserSuccess,
} = authSlice.actions;
export default authSlice.reducer;
