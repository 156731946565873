import { call, put, takeEvery, all } from "redux-saga/effects";
import axios from "../../api";
import {
  loginSuccess,
  loginFailure,
  logoutSuccess,
  signupSuccess,
  getLoggedInUserSuccess,
} from "./authState";
import { notification } from "antd";
import { jwtDecode } from "jwt-decode";

const openNotificationWithIcon = (type, description) => {
  notification.destroy();
  notification[type]({
    message: type === "success" ? "Operation Successful" : "Operation Failed",
    description: description,
  });
};

function* workLogin(action) {
  const { email, password } = action.payload;
  try {
    const userData = {
      email: email,
      password: password,
    };

    const response = yield call(() =>
      axios.post(`${process.env.REACT_APP_BASE_URL}clientLogin`, userData)
    );
    yield put(loginSuccess(response.data));
  } catch (error) {
    // console.log(error)
    openNotificationWithIcon("error", " Incorrect Username or Password");
    yield put(loginFailure("Incorrect Username or Password"));
  }
}

export function* workLoginSuccess(action) {
  openNotificationWithIcon("success", "Signin successfull");
  yield localStorage.setItem("clientToken", action.payload.token);
}

function performLogout() {
  localStorage.removeItem("clientToken");
  return Promise.resolve();
}

function* workLogout() {
  try {
    yield call(performLogout);
    yield put(logoutSuccess());
  } catch (error) {
    // console.log(error);
  }
}

function* workGetLoggedInUser(action) {
  try {
    const token = action.payload.idToken;
    const response = yield call(() =>
      axios.get(
        `${process.env.REACT_APP_BASE_URL}clientProfile/${jwtDecode(token).id}`
      )
    );
    const user = response.data;
    yield put(getLoggedInUserSuccess(user));
  } catch (error) {
    // console.log(error);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery("auth/login", workLogin),
    takeEvery("auth/loginSuccess", workLoginSuccess),
    takeEvery("auth/getLoggedInUser", workGetLoggedInUser),
    takeEvery("auth/logout", workLogout),
  ]);
}
