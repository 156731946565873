import styled from "styled-components";

export const Wrapper = styled.div`
  .geocontainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f5f5f5;
    padding: 0 5%;
  }

  .geocard {
    margin-top: 1.5rem;
    width: 38rem;
    background: #ffffff;
    border-radius: 16px;
    padding: 50px;
  }

  .geotitle {
    font-size: 1.5rem;
    font-weight: 700;
    color: #000000;
    margin-bottom: 0;
    font-family: Poppins;
    text-align: left;
  }

  .geobgparagraph {
    margin-top: 1.5rem;
    justify-content: flex-end;
    list-style-type: decimal;
  }

  .subtitle {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 30px;
  }
  .footer {
    padding-top: 0;
  }

  .geoparag::-webkit-scrollbar {
    width: 8px;
  }

  .geoparag::-webkit-scrollbar-thumb {
    background-color: #a9a9a9;
    border-radius: 4px;
  }

  .geoparag::-webkit-scrollbar-track {
    background-color: #dfdfdf;
  }

  .samplereq::-webkit-scrollbar {
    height: 4px; /* Use height for horizontal scrollbar */
  }

  .samplereq::-webkit-scrollbar-thumb {
    background-color: #a9a9a9;
    border-radius: 4px;
  }

  .samplereq::-webkit-scrollbar-track {
    background-color: #dfdfdf;
  }

  /* Responsive Styles */
  @media (max-width: 768px) {
    .geocard {
      width: 100%;
      padding: 20px;
    }
  }
`;
