import React, { Component } from "react";
import LayoutContentWrapper from "../../components/utility/layoutWrapper";
import DashboardWidgetWrapper from "./widgets-wrapper";
import StickerWidget from "./sticker-widget";
import { Row, Col } from "antd";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { WidgetBox } from "./style";
import { PlusOutlined } from "@ant-design/icons";
import "./newproject.css";
import Card from "../NewProject/newproject";
import { Modal } from "antd";
import Table from "./table";

const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

class Dashboard extends Component {
  state = {
    isModalVisible: false,
  };

  handleProject = () => {
    this.setState({ isModalVisible: true });
  };

  handleModalCancel = () => {
    this.setState({ isModalVisible: false });
  };
  render() {
    const wisgetPageStyle = {
      display: "flex",
      width: "100%",
      flexFlow: "row wrap",
      alignItems: "flex-start",
      overflow: "hidden",
    };
    return (
      <LayoutContentWrapper>
        <div style={wisgetPageStyle}>
          <Row gutter={0} style={{ width: "100%" }}>
            <Col lg={6} md={12} sm={24} xs={24} style={{ marginBottom: "2%" }}>
              <DashboardWidgetWrapper>
                {/* Sticker Widget */}
                <StickerWidget
                  title="Project Title 1"
                  text="Project description goes here"
                  fontColor="#FFFFFF"
                  bgColor="#2A29CC"
                  createdat="2:00 PM"
                />
              </DashboardWidgetWrapper>
            </Col>
            <Col lg={6} md={12} sm={24} xs={24} style={{ marginBottom: "2%" }}>
              <DashboardWidgetWrapper>
                {/* Sticker Widget */}
                <StickerWidget
                  title="Project Title 2"
                  text="Project description goes here"
                  fontColor="#000000"
                  bgColor="#FFFFFF"
                  createdat="3:00 PM"
                />
              </DashboardWidgetWrapper>
            </Col>
            <Col lg={6} md={12} sm={24} xs={24} style={{ marginBottom: "2%" }}>
              <DashboardWidgetWrapper>
                <div className="newproject" onClick={this.handleProject}>
                  <PlusOutlined
                    className="anticon"
                    style={{ color: "#6777CC" }}
                  />
                  <h3 style={{ color: "#6777CC" }}>Create Project </h3>
                </div>
              </DashboardWidgetWrapper>
            </Col>
          </Row>

          <Row gutter={0} style={{ width: "100%" }}>
            <Col lg={12} md={24} sm={24} xs={24} style={{ marginBottom: "2%" }}>
              <DashboardWidgetWrapper>
                {/* <h3>Activity</h3> */}
                <WidgetBox>
                  <ResponsiveContainer width="100%" height="100%">
                    <AreaChart
                      width={500}
                      height={400}
                      data={data}
                      margin={{
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 0,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Area
                        type="monotone"
                        dataKey="uv"
                        stroke="#8884d8"
                        fill="#D9D9D9"
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </WidgetBox>
              </DashboardWidgetWrapper>
            </Col>
            <Col lg={12} md={24} sm={24} xs={24} style={{ marginBottom: "2%" }}>
              <DashboardWidgetWrapper>
                {/* <h3>News</h3> */}
                <WidgetBox>
                  <ResponsiveContainer width="100%" height="100%">
                    <Table />
                  </ResponsiveContainer>
                </WidgetBox>
              </DashboardWidgetWrapper>
            </Col>
          </Row>

          <Row gutter={0} style={{ width: "100%" }}>
            <Col lg={12} md={24} sm={24} xs={24} style={{ marginBottom: "2%" }}>
              <DashboardWidgetWrapper>
                {/* <h3>Billing & Usage</h3> */}
                <WidgetBox>
                  <ResponsiveContainer
                    width="100%"
                    height="100%"
                  ></ResponsiveContainer>
                </WidgetBox>
              </DashboardWidgetWrapper>
            </Col>
            <Col lg={12} md={24} sm={24} xs={24} style={{ marginBottom: "2%" }}>
              <DashboardWidgetWrapper>
                {/* <h3>Upcoming Bill</h3> */}
                <WidgetBox>
                  <ResponsiveContainer
                    width="100%"
                    height="100%"
                  ></ResponsiveContainer>
                </WidgetBox>
              </DashboardWidgetWrapper>
            </Col>
          </Row>
        </div>
        <Modal
          title="Create Project"
          visible={this.state.isModalVisible}
          onCancel={this.handleModalCancel}
          footer={null}
        >
          <Card />
        </Modal>
      </LayoutContentWrapper>
    );
  }
}

export default Dashboard;
