import React, { Component } from "react";
import Card from "../../components/Cards/cards.js";
// import Logo from "../../components/utility/logo";
class Navigation extends Component {
  render() {
    const responses = {
      200: {
        geocoding: {
          version: "0.2",
          query: {
            size: 10,
            private: false,
            "point.lat": 9.003764,
            "point.lon": 38.813257,
            "boundary.circle.lat": 9.003764,
            "boundary.circle.lon": 38.813257,
            lang: {
              name: "English",
              iso6391: "en",
              iso6393: "eng",
              via: "header",
              defaulted: false,
            },
            querySize: 20,
          },
          engine: { name: "Pelias", author: "Mapzen", version: "1.0" },
          timestamp: 1700839471521,
        },
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: { type: "Point", coordinates: [38.813257, 9.003764] },
            properties: {
              id: "node/7037142483",
              gid: "openstreetmap:venue:node/7037142483",
              layer: "venue",
              source: "openstreetmap",
              source_id: "node/7037142483",
              name: "Gerji Mebrat Haile",
              confidence: 1,
              distance: 0,
              accuracy: "point",
              label: "Gerji Mebrat Haile",
              addendum: {
                osm: { operator: "Addis Ababa Bus Service Enterprise" },
              },
            },
          },
          {
            type: "Feature",
            geometry: { type: "Point", coordinates: [38.813016, 9.003823] },
            properties: {
              id: "node/838832129",
              gid: "openstreetmap:venue:node/838832129",
              layer: "venue",
              source: "openstreetmap",
              source_id: "node/838832129",
              name: "Wisdom Micro-Finance Institution S.C. - Head Office",
              confidence: 0.8,
              distance: 0.027,
              accuracy: "point",
              label: "Wisdom Micro-Finance Institution S.C. - Head Office",
            },
          },
          {
            type: "Feature",
            geometry: { type: "Point", coordinates: [38.811853, 9.004156] },
            properties: {
              id: "node/838832134",
              gid: "openstreetmap:venue:node/838832134",
              layer: "venue",
              source: "openstreetmap",
              source_id: "node/838832134",
              name: "Tsega Pharmacy",
              confidence: 0.7,
              distance: 0.16,
              accuracy: "point",
              label: "Tsega Pharmacy",
            },
          },
          {
            type: "Feature",
            geometry: { type: "Point", coordinates: [38.812248, 9.002624] },
            properties: {
              id: "node/5661585021",
              gid: "openstreetmap:venue:node/5661585021",
              layer: "venue",
              source: "openstreetmap",
              source_id: "node/5661585021",
              name: "ናሆም",
              street: "gerji",
              confidence: 0.7,
              distance: 0.169,
              accuracy: "point",
              label: "ናሆም",
            },
          },
          {
            type: "Feature",
            geometry: { type: "Point", coordinates: [38.811644, 9.003678] },
            properties: {
              id: "node/8552489718",
              gid: "openstreetmap:venue:node/8552489718",
              layer: "venue",
              source: "openstreetmap",
              source_id: "node/8552489718",
              name: "Aroma",
              street: "Road to Gergi Giorgis",
              confidence: 0.7,
              distance: 0.178,
              accuracy: "point",
              label: "Aroma",
              addendum: { osm: { phone: "0930333385" } },
            },
          },
          {
            type: "Feature",
            geometry: { type: "Point", coordinates: [38.811445, 9.004261] },
            properties: {
              id: "node/838832146",
              gid: "openstreetmap:venue:node/838832146",
              layer: "venue",
              source: "openstreetmap",
              source_id: "node/838832146",
              name: "Shampion Business Center",
              confidence: 0.7,
              distance: 0.207,
              accuracy: "point",
              label: "Shampion Business Center",
            },
          },
          {
            type: "Feature",
            geometry: { type: "Point", coordinates: [38.811426, 9.004366] },
            properties: {
              id: "node/3875830508",
              gid: "openstreetmap:venue:node/3875830508",
              layer: "venue",
              source: "openstreetmap",
              source_id: "node/3875830508",
              name: "King's Pizza",
              confidence: 0.7,
              distance: 0.212,
              accuracy: "point",
              label: "King's Pizza",
            },
          },
          {
            type: "Feature",
            geometry: { type: "Point", coordinates: [38.811366, 9.004446] },
            properties: {
              id: "node/3875820123",
              gid: "openstreetmap:venue:node/3875820123",
              layer: "venue",
              source: "openstreetmap",
              source_id: "node/3875820123",
              name: "Amanaya Guesthouse",
              confidence: 0.7,
              distance: 0.221,
              accuracy: "point",
              label: "Amanaya Guesthouse",
            },
          },
          {
            type: "Feature",
            geometry: { type: "Point", coordinates: [38.811241, 9.004448] },
            properties: {
              id: "node/3875823665",
              gid: "openstreetmap:venue:node/3875823665",
              layer: "venue",
              source: "openstreetmap",
              source_id: "node/3875823665",
              name: "Babu Cafe",
              confidence: 0.7,
              distance: 0.234,
              accuracy: "point",
              label: "Babu Cafe",
            },
          },
          {
            type: "Feature",
            geometry: { type: "Point", coordinates: [38.8111, 9.003091] },
            properties: {
              id: "node/6691125988",
              gid: "openstreetmap:venue:node/6691125988",
              layer: "venue",
              source: "openstreetmap",
              source_id: "node/6691125988",
              name: "112131  ገርጂ  አፓርታማ",
              confidence: 0.7,
              distance: 0.249,
              accuracy: "point",
              label: "112131  ገርጂ  አፓርታማ",
            },
          },
        ],
        bbox: [38.8111, 9.002624, 38.813257, 9.004448],
      },
      400: {
        error: "Latitude and longitude are required",
        code: "Bad Request",
      },

      401: {
        error: "Unauthorized - Invalid API key",
        code: "Unauthorized",
      },
    };

    const codes = {
      html: {
        status: "Reverse Geocoding",
        data: [
          {
            service_id: "12345",
            service_name: "Arat Kilo Service 1",
            description: "This is a sample service at Arat Kilo.",
            location: "Arat Kilo, Addis Ababa",
            rating: 4.5,
            reviews: 20,
            category: "Food",
            price_range: "$$",
            hours_of_operation: "Mon-Sun: 9:00 AM - 10:00 PM",
            contact: "Phone: (123) 456-7890",
            website: "http://www.sample-service.com",
          },
          {
            service_id: "67890",
            service_name: "Arat Kilo Service 2",
            description: "Another sample service in Arat Kilo.",
            location: "Arat Kilo, Addis Ababa",
            rating: 4.0,
            reviews: 15,
            category: "Retail",
            price_range: "$$$",
            hours_of_operation: "Mon-Sat: 10:00 AM - 8:00 PM",
            contact: "Phone: (234) 567-8901",
            website: "http://www.another-service.com",
          },
          {
            service_id: "1",
            service_name: "Service 1",
            description: "This is service 1.",
            location: "Location 1",
            rating: 4.5,
            reviews: 20,
            category: "Category A",
            price_range: "$$",
            hours_of_operation: "Mon-Sun: 9:00 AM - 10:00 PM",
            contact: "Phone: (123) 456-7890",
            website: "http://www.service1.com",
          },
          {
            service_id: "2",
            service_name: "Service 2",
            description: "This is service 2.",
            location: "Location 2",
            rating: 4.0,
            reviews: 15,
            category: "Category B",
            price_range: "$$$",
            hours_of_operation: "Mon-Sat: 10:00 AM - 8:00 PM",
            contact: "Phone: (234) 567-8901",
            website: "http://www.service2.com",
          },
          // Continue adding more entries here...
          {
            service_id: "421",
            service_name: "Service 421",
            description: "This is service 421.",
            location: "Location 421",
            rating: 3.5,
            reviews: 10,
            category: "Category D",
            price_range: "$$$",
            hours_of_operation: "Mon-Fri: 8:00 AM - 6:00 PM",
            contact: "Phone: (987) 654-3210",
            website: "http://www.service421.com",
          },
        ],
      },
      javascript: {
        totalDistance: 71,
        msg: "- Reverse Geocoding",
      },
    };

    return (
      <Card
        title="Reverse Geocoding"
        description="Reverse Geocoding converts coordinates into readable addresses, enabling streamlined location-based services. It offers search suggestions, autocomplete for areas, nearby service lookup, category-based search, and quick retrieval of location details from coordinates."
        apiButtonText="https://apigateway.ethiomaps.com/apigateway/services?API_KEY=14529b5d-a679-4046-b8be-cb32e33b4c60&service=reverse_geocoding&lat=9.0328&lon=38.7633"
        samplereqText="https://apigateway.ethiomaps.com/apigateway/services?API_KEY=14529b5d-a679-4046-b8be-cb32e33b4c60&service=reverse_geocoding&lat=9.0328&lon=38.7633"
        responses={responses}
        codes={codes}
      />
    );
  }
}

export default Navigation;
