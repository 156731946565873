import { combineReducers } from "redux";
import appReducer from "./app/appState";
import authReducer from "./auth/authState";

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  // Add other reducers here
});

export default rootReducer;
