import React from "react";
// import { Link } from 'react-router-dom';

const Logo = ({ collapsed }) => {
  return (
    <div className="isoLogoWrapper">
      {collapsed ? (
        <div>
          <h3>
            {/* <Link to="/dashboard">
              <i className={siteConfig.siteIcon} />
            </Link> */}
          </h3>
        </div>
      ) : (
        <h3>
          <a to="#" style={{ color: "#131466" }} className="text-3xl">
            ETHIO<strong>MAPS</strong>
          </a>
        </h3>
      )}
    </div>
  );
};

export default Logo;
