import React from 'react';

const Card = () => {
  return (
    <div className="max-w-sm mx-auto bg-white p-5 rounded-md shadow-lg">
      <label htmlFor="projectTitle" className="block text-gray-700 text-sm font-bold mb-2">
        Project Title
      </label>
      <input 
        type="text" 
        id="projectTitle" 
        placeholder="Enter Project Title Here" 
        className="border-b-2 border-gray-400 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      />
      <button className="mt-5 bg-blue-800 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
        Create Project!
      </button>
    </div>
  );
}

export default Card;
