import React from 'react';
import { Divider, Table } from 'antd';
const columns = [
  {
    title: 'Project',
    dataIndex: 'project',
  },
  {
    title: 'Usage',
    dataIndex: 'usage',
  },
  {
    title: 'Cost',
    dataIndex: 'cost',
  },
];
const data = [
  {
    key: '1',
    project: 'Project Title 1',
    usage: '1,000,000 Hits',
    cost: '$100,000',
  },
  {
    key: '2',
    project: 'Project Title 2',
    usage: '2,000,000 Hits',
    cost: '$200,000',
  },
  {
    key: '3',
    project: 'Project Title 3',
    usage: '3,000,000 Hits',
    cost: '$300,000',
  },
];
const App = () => (
  <>
  
    <Divider>Billing & Usage</Divider>
    <Table columns={columns} dataSource={data} size="small" />
  </>
);
export default App;