import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FaGoogle, FaFacebookSquare } from "react-icons/fa";
import {
  Title,
  WelcomeText,
  SignInText,
  HorizontalLine,
  ForgotPasswordText,
  OrText,
  SignUpText,
} from "./login.style";
import { Button, Checkbox, Form, Input } from "antd";
import { Link } from "react-router-dom";
import { login } from "../../../redux/auth/authState";
import { useNavigate } from "react-router-dom";

const Login = ({ login, signinSuccess, update2 }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (signinSuccess) {
      navigate("/ethiomaps");
    }
  }, [signinSuccess, navigate]);

  const onFinish = (values) => {
    login(values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div
      className="flex items-center justify-center mobile:inline"
      style={{ backgroundColor: "#EAEAEA", minHeight: "100dvh" }}
    >
      <div
        className="bg-white w-3/5 h-5/6 flex mobile:w-full mobile:h-full"
        style={{ borderRadius: "10px" }}
      >
        <div
          className="flex-grow w-1/2 mobile:hidden"
          style={{ backgroundColor: "#9A9A9A", borderRadius: "10px 0 0 10px" }}
        ></div>
        <div className="flex-grow w-1/2 bg-white-500 mobile:w-full mobile:h-full">
          <Title>
            ETHIO<b>MAPS</b>
          </Title>
          <WelcomeText>Welcome Back</WelcomeText>
          <SignInText className="sign-in-text">
            Sign in using your credentials
          </SignInText>
          <HorizontalLine />
          <Form
            name="basic"
            layout="vertical"
            style={{
              maxWidth: 600,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className="px-16"
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                },
              ]}
              style={{ marginBottom: 10 }}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
              style={{ marginBottom: 10 }}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item name="remember" valuePropName="checked">
              <Checkbox>Remember me</Checkbox>
            </Form.Item>

            <Form.Item>
              <ForgotPasswordText>
                Forgot Password? <a href="#">Click here to reset</a>
              </ForgotPasswordText>
              <Button
                className="w-full text-white"
                style={{ background: "#626262", marginTop: "20px" }}
                htmlType="submit"
              >
                Sign In
              </Button>
            </Form.Item>
          </Form>
          {/* <OrText>Or</OrText> */}
          {/* <div className='flex justify-center' >
     
                    <Button onClick={handleGoogleClick} className='mr-2' icon={<FaGoogle />}>Google</Button>

                    <Button onClick={handleFacebookClick} className='ml-2' icon={<FaFacebookSquare />}>Facebook</Button>
                  
                </div> */}
          {/* <SignUpText>
            Don't have an account?<Link to="/signup"> Sign Up</Link>
          </SignUpText> */}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    ...state.auth,
  };
}
const mapDispatchToProps = {
  login,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
