import React, { useState, useEffect } from "react";
import Topbar from "../Topbar/Topbar";
import Sidebar from "../Sidebar/Sidebar";
import { Outlet } from "react-router-dom";
import { getLoggedInUser } from "../../redux/auth/authState";
import { connect } from "react-redux";

const App = ({ getLoggedInUser, idToken, updatedApiKey }) => {
  const [isSidebarOpen, setSidebarOpen] = useState(window.innerWidth >= 768); // Set to false for xs and sm initially

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setSidebarOpen(window.innerWidth >= 640);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    console.log(updatedApiKey);
    getLoggedInUser({ idToken });
  }, [updatedApiKey]);

  return (
    <div className="relative flex overflow-x-hidden ">
      {isSidebarOpen && (
        <div className="absolute w-[16rem] flex justify-center w-[16rem]">
          <Sidebar />
        </div>
      )}
      <div
        className="w-full flex flex-col flex-auto"
        style={{
          marginLeft: isSidebarOpen ? "16rem" : "0",
          transition: "margin-left 0.2s",
        }}
      >
        <div className="bg-[#f5f5f5]">
          <Topbar toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} />
          <Outlet />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedInUser: state.auth.loggedInUser,
    idToken: state.auth.idToken,
    updatedApiKey: state.auth.updatedApiKey,
  };
};

const mapDispatchToProps = {
  getLoggedInUser: getLoggedInUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
